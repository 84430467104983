import React from 'react';
import { ReactComponent as HeadphoneIcon } from './assets/icons/headphones.svg';

function DisplayImageOrSound(props) {
    // return(
    //     <div>type{props.type}</div>
    // )
    switch (props.type) {
        case 'sound':
            return (
                <React.Fragment>
                    <HeadphoneIcon
                        style={{
                            width: 35,
                            height: 35,
                            margin: '0 auto'
                        }}
                        title={props.fileName}
                        alt={props.fileName}
                    />
                    <div
                        style={{
                            display: 'inline-block',
                            paddingLeft: 5,
                            fontFamily: 'Mulish',
                            fontSize: 14,
                            fontWeight: 'bold',
                        }}
                        title={props.fileName}
                        alt={props.fileName}
                    >
                        {/* {props.fileName.length <= 30 ? props.fileName : props.fileName.substr(1, 30) + ' ...'} */}
                        {props.fileName}
                        </div>
                </React.Fragment>
            );
        case 'image':
            return (
                <React.Fragment>
                    <img
                        style={{
                            width: 40,
                            height: 40,
                            borderRadius: '18%'
                        }}
                        src={process.env.REACT_APP_FILE_URL + '/' + props.source}
                        title={props.fileName}
                        alt={props.fileName}
                    />
                    <div
                        style={{
                            display: 'inline-block',
                            paddingLeft: 5,
                            fontFamily: 'Mulish',
                            fontSize: 14,
                            fontWeight: 'bold',
                        }}
                        title={props.fileName}
                        alt={props.fileName}
                    >{props.fileName.length <= 30 ? props.fileName : props.fileName.substr(1, 30) + ' ...'}</div>
                </React.Fragment>
            )
        default:
            return (
                <></>
            )
    }
}
export default DisplayImageOrSound;
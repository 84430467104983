import React from 'react';
import Grid from '@material-ui/core/Grid';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { makeStyles } from '@material-ui/core/styles';
import MobileSideNavButton from '../components/buttons/MobileSideNavButton';
import Routs from "../configs/Routes";
import Hidden from '@material-ui/core/Hidden';
import IntroductionVideo from '../components/IntroductionVideo';
import { connect } from "react-redux";
import { ReactComponent as PackageIcon } from '../assets/icons/package.svg';
import { ReactComponent as PieChartIcon } from '../assets/icons/pie-chart.svg';
import { ReactComponent as CreditCardIcon } from '../assets/icons/credit-card.svg';
import { ReactComponent as SettingsIcon } from '../assets/icons/settings.svg';
import { ReactComponent as PlussquareIcon } from '../assets/icons/plus-square.svg';
import { ReactComponent as LogoutIcon } from '../assets/icons/log-out.svg';
import AppBar from "../AppBar";
import { withStyles } from "@material-ui/core/styles";
import LeftSidebarItems from './LeftSidebarItems';
import Container from '@material-ui/core/Container';
import store from '../redux/store';
import { setUserinfo, setUserLogout } from "../redux/actions";

const drawerWidth = 200;

const useStyles = (theme) => ({
    // containerRoot: {
    //     flexGrow: 1,
    // },
    paper: {
        [theme.breakpoints.down('md')]: {
            height: 70,
        },
        [theme.breakpoints.up('md')]: {
            height: 108,
        },
        width: '100%',
    },
    paper1: {
        width: '100%',
        textAlign: 'center',
        paddingTop: '30px'
    },
    paper2: {
        width: '100%',
        minHeight: 'calc(100vh - 120px)',
        backgroundColor: '#FFFFFF',
        paddingTop: '30px'
    },
    paper3: {
        width: '100%',
        textAlign: 'center',
        paddingTop: '30px'
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
        whiteSpace: 'nowrap',
    },
    drawerOpen: {
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(10) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(12) + 1,
        },
    },
    drawer_list_item: {
        display: "list-item",
        paddingLeft: "0px",
        paddingRight: "0px",
        textAlign: "center"
    },
    drawer_list_item_icon: {
        display: "inherit"
    },
    drawer_list_item_text: {
        fontFamily: 'IranSans',
        fontSize: '8px'
    },
    drawer_list_item_text_open: {
        fontFamily: 'IranSans',
        fontSize: '12px'
    }
});

class MainContainer extends React.Component {

    constructor(props) {
        super(props);
    }

    state = {
        main_Drawer: false,
        spacing: 0
    }

    toggleDrawer = () => {
        this.setState({
            main_Drawer: !this.state.main_Drawer
        })
    };

    logOut = () => {
        this.toggleDrawer();
        // get OTP
        let formData = new FormData();
        formData.append('mobile', window.localStorage.getItem('mobile'));
        fetch(`${process.env.REACT_APP_API_URL}auth/logout`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                // 'X-ACCESS-ROLE': 1
            },
            referrer: 'no-referrer', // no-referrer, *client
            body: formData, // body data type must match "Content-Type" header
        })
            .then(logoutResult => {
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('mobile');
                window.localStorage.removeItem('last-location');
                store.dispatch(setUserLogout());
                let userData = {
                    isLogin: false,
                    token: null
                }
                store.dispatch(setUserinfo(userData));
                window.location.href = '/login';
                return logoutResult;
            })
    }

    goToSettings = () => {
        this.toggleDrawer();
        window.location.href = '#/settings';
    }

    render() {
        const { classes } = this.props;
        return (
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <SwipeableDrawer
                        open={this.state.main_Drawer}
                        onClose={() => this.toggleDrawer('left', false)}
                        onOpen={() => this.toggleDrawer('left', true)}
                    >
                        <div
                            style={{
                                backgroundColor: '#182A7D',
                                width: '331px',
                                height: '100%',
                                textAlign: 'center',
                                position: 'relative',
                                paddingTop: 45
                            }}
                        >

                            <MobileSideNavButton
                                icon={<PlussquareIcon />}
                                href={`/new-program`}
                                toggleDrawer={() => this.toggleDrawer()}
                            >
                                Add new program
                            </MobileSideNavButton>

                            <MobileSideNavButton
                                toggleDrawer={() => this.toggleDrawer()}
                                icon={<PackageIcon />}
                                href={`/programs-list`}
                            >
                                Programs
                            </MobileSideNavButton>

                            <MobileSideNavButton
                                toggleDrawer={() => this.toggleDrawer()}
                                icon={<PieChartIcon />}
                                href={`/reports`}
                            >
                                Reports
                            </MobileSideNavButton>

                            <MobileSideNavButton
                                toggleDrawer={() => this.toggleDrawer()}
                                icon={<CreditCardIcon />}
                                href={`/finance`}
                            >
                                Finance
                            </MobileSideNavButton>

                            <div
                                style={{
                                    position: 'absolute',
                                    bottom: '25px'
                                }}
                            >
                                {[
                                    {
                                        'label': 'Settings',
                                        'icon': <SettingsIcon />,
                                        'onClick': this.goToSettings
                                    },
                                    {
                                        'label': 'Logout',
                                        'icon': <LogoutIcon />,
                                        'onClick': this.logOut
                                    }
                                ].map(
                                    (item, index) =>
                                        <div
                                            key={index}
                                            style={{
                                                display: 'flex',
                                                marginBottom: '20%'
                                            }}
                                        >
                                            <div
                                                style={{
                                                    color: 'white',
                                                    margin: '0px 20px'
                                                }}
                                            >{item.icon}</div>
                                            <div
                                                style={{
                                                    color: 'white',
                                                    fontFamily: 'Mulish',
                                                    fontStyle: 'normal',
                                                    fontWeight: '700',
                                                    fontSize: '16px',
                                                    lineHeight: '20px',
                                                }}
                                                onClick={() => item.onClick()}
                                            >
                                                {item.label}
                                            </div>
                                        </div>
                                )}
                            </div>

                        </div>
                    </SwipeableDrawer>
                    <div className={classes.paper} >
                        <AppBar
                            toggleDrawer={this.toggleDrawer}
                        />

                    </div>
                </Grid>

                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Grid
                        container
                        // className={classes.containerRoot}
                        spacing={0}
                        style={{
                            flexGrow: 1
                        }}
                    >
                        <Grid item xs={12}>
                            <Grid container justify="center" spacing={this.state.spacing}>

                                <Hidden only={['xs', 'sm']}>
                                    <Grid item xs={12} sm={12} md={3} lg={2} xl={2} >
                                        <LeftSidebarItems />
                                    </Grid>
                                </Hidden>

                                <Grid item xs={12} sm={12} md={9} lg={7} xl={7}>
                                    <div className={classes.paper2}>
                                        <Container>
                                            <Routs />
                                        </Container>
                                    </div>
                                </Grid>

                                <Hidden only={['xs', 'sm', 'md']}>
                                    <Grid item xs={12} sm={12} md={3} lg={3} xl={3}>
                                        {this.props.isLogin ?
                                            <div className={classes.paper3}>

                                                {[1, 2, 3].map(
                                                    (item, index) =>
                                                        <div
                                                            key={index}
                                                            style={{
                                                                margin: '15px 2px'
                                                            }}
                                                        >
                                                            <IntroductionVideo />
                                                        </div>
                                                )}

                                            </div>
                                            : null
                                        }
                                    </Grid>
                                </Hidden>

                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = (state) => {
    const isLogin = state.isLogin;

    return {
        isLogin
    }
};
export default connect(mapStateToProps)(withStyles(useStyles)(MainContainer));

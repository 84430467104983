import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Segment from './Segment';

class SegmentLine extends React.Component {

    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.segmentLineRef = React.createRef();
    }

    state = {
        showContextMenu: false,
        contextMenuOffsetX: 20,
        segments: [],
        weAreInResizeMode: false
    }

    myRef;
    m_pos;
    BORDER_SIZE = 4;
    direction;
    rightPostion;
    leftPostion;
    summary = 0;

    componentDidMount() {
        this.setState({
            timelineWidthInPixel: parseInt(getComputedStyle(document.getElementById('timeline')).width)
        })
    }

    resizeLeft = (e) => {
        const dx = this.m_pos - e.x;
        this.m_pos = e.x;
        this.myRef.current.style.right = e.currentTarget.rightPostion + "px";
        this.myRef.current.style.width = (parseInt(getComputedStyle(this.myRef.current, '').width) + dx) + "px";
    }

    resizeRight = (e) => {
        const dx = this.m_pos - e.x;
        this.m_pos = e.x;
        this.myRef.current.style.left = e.currentTarget.leftPostion + "px";
        this.myRef.current.style.width = (parseInt(getComputedStyle(this.myRef.current, '').width) - dx) + "px";
    }

    mu = (e) => {
        this.myRef.current.removeEventListener("mousemove", this.resizeLeft, false);
    }

    reArrangeDurations = (distancePosition, index) => {
        let bakedWidth = (index === 0 ? distancePosition : distancePosition - (this.state.segments[index - 1].left + this.state.segments[index - 1].width + 3));
        let tempSegments = this.state.segments;
        tempSegments[index].width = bakedWidth;
        tempSegments[index].duration = (bakedWidth * this.props.mediaDuration) / this.state.timelineWidthInPixel;

        for (let i = index + 1; i < tempSegments.length; i++) {
            tempSegments[i].left = tempSegments[i - 1].left + tempSegments[i - 1].width + 3;
            tempSegments[i].startSecond = Number(tempSegments[i - 1].startSecond) + Number(tempSegments[i - 1].duration);
        }

        for (let i = index; i < tempSegments.length; i++) {
            tempSegments[i].endSecond = Number(tempSegments[i].startSecond) + Number(tempSegments[i].duration);
        }

        this.setState({
            segments: tempSegments
        });
        this.callParent(tempSegments);
    }

    callParent = (tempSegments) => {
        this.props.reArrangeDurations(tempSegments);
    }

    setContextMenuOffsetX = (param) => {
        this.setState({
            contextMenuOffsetX: param
        })
    }

    displayContextmenu = (event) => {
        this.segmentLineRef.current.focus();
        this.setShowContextMenu(true);
        this.setContextMenuOffsetX(parseInt(event.nativeEvent.offsetX) - 95);
    }

    hideContextmenu = (event) => {
        this.setShowContextMenu(false);
    }

    setShowContextMenu = (show) => {
        this.setState({
            showContextMenu: show
        })
    }

    addSegment = (e, type) => {
        e.preventDefault();
        switch (type) {
            case 'rest':
                this.props.addSegment({
                    label: 'Rest',
                    type: 'rest',
                    typeNumber: 0,
                    backgroundColor: '#EFEFF0'
                });
                break;

            case 'workout':
                this.props.addSegment({
                    label: 'Training one',
                    type: 'workout',
                    typeNumber: 2,
                    backgroundColor: '#FCC0CE'
                });
                break;

            case 'explaination':
                this.props.addSegment({
                    label: 'Explaination',
                    type: 'explaination',
                    typeNumber: 1,
                    backgroundColor: '#EBFF00'
                });
                break;
            default:
        }
        this.setShowContextMenu(false);
        e.stopPropagation();
    }

    calculateWidth = (durationInSecond) => {
        let segmentWidth = parseInt((durationInSecond * this.state.timelineWidthInPixel) / this.props.mediaDuration);
        return segmentWidth;
    }

    factory = (rawObject) => {
        for (let i = 0; i < rawObject.length; i++) {
            rawObject[i].width = this.calculateWidth(rawObject[i].duration);
            rawObject[i].left = i === 0 ? 0 : rawObject[i - 1].left + rawObject[i - 1].width + 3;
        }
        return rawObject;
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let bakedSegmentsObject = this.factory(nextProps.segments);
        this.setState({
            segments: bakedSegmentsObject
        })
    }

    sayWeAreInResizeMode = () => {
        this.setState({
            weAreInResizeMode: true
        });
    }

    sayWeAreNotInResizeMode = () => {
        this.setState({
            weAreInResizeMode: false
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div
                tabindex="0"
                ref={this.segmentLineRef}
                className={classes.segmentLine}
                onClick={(event) => this.displayContextmenu(event)}
                onBlur={(event) => this.hideContextmenu(event)}
            >
                {
                    this.state.segments.map(
                        (item, index) => !item.isDeleted && (
                            <Segment
                                key={index}
                                data={item}
                                weAreInResizeMode={this.state.weAreInResizeMode}
                                reArrangeDurations={(distancePosition) => this.reArrangeDurations(distancePosition, index)}
                                onMouseDownHappened={() => this.sayWeAreInResizeMode()}
                                onMouseUpHappened={() => this.sayWeAreNotInResizeMode()}
                                containerwidth={this.state.timelineWidthInPixel}
                                allSegments={this.state.segments}
                            />
                        )
                    )
                }
                {
                    this.state.showContextMenu ?
                        <div
                            style={{
                                position: 'absolute',
                                top: 37,
                                zIndex: 1,
                                left: this.state.contextMenuOffsetX
                            }}
                        >
                            <div
                                style={{
                                    position: 'relative',
                                    width: 198,
                                    height: 203,
                                }}
                            >
                                <div
                                    style={{
                                        width: 25,
                                        height: 25,
                                        backgroundColor: 'black',
                                        transform: 'rotate(45deg)',
                                        position: 'absolute',
                                        top: 0,
                                        left: '42%',
                                        borderRadius: 7,
                                    }}
                                />
                                <div
                                    style={{
                                        backgroundColor: 'black',
                                        width: '100%',
                                        height: '96%',
                                        position: 'absolute',
                                        bottom: 0,
                                        borderRadius: 8
                                    }}
                                >
                                    <ul
                                        style={{
                                            lineHeight: '34px',
                                            listStyleType: 'none',
                                            paddingLeft: '21px'
                                        }}
                                    >
                                        <li onClick={(e) => this.addSegment(e, 'rest')} className={classes.contextMenuItem}>Rest time</li>
                                        <li onClick={(e) => this.addSegment(e, 'workout')} className={classes.contextMenuItem}>Add workout</li>
                                        <li onClick={(e) => this.addSegment(e, 'explaination')} className={classes.contextMenuItem}>Explaination</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        : null
                }
            </div>
        );
    }
}

const myStyle = (theme) => ({
    segmentLine: {
        position: 'relative',
        width: '100%',
        height: '24px',
        cursor: "url('/cursor/plus.svg'), pointer"
    },
    contextMenuItem: {
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontSize: 16,
        color: '#FFFFFF',
        '&:hover': {
            cursor: 'pointer',
            textDecoration: 'underline',
            fontWeight: 900,
        }
    },
});

export default withStyles(myStyle)(SegmentLine);

import React from 'react';
import { styled } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import { Link as RouterLink } from 'react-router-dom';

const MyButton = styled(Button)({
    background: '#F11F51',
    border: 0,
    borderRadius: 10,
    color: 'white',
    height: 54,
    padding: '0 8px',
    fontFamily: 'Mulish',
    fontWeight: '700',
    width: 194,
    fontSize: '14px',
    '&:hover': {
        backgroundColor: '#ca0735',
    },
});

function NewProgramButton(props) {
    return (
        <Link
            to={props.href}
            component={RouterLink}
            style={{
                textDecoration: 'none'
            }}
        >
            <MyButton
                startIcon={props.icon}
                {...props}
            >
                NEW PROGRAM
            </MyButton>
        </Link>
    )
}

export default NewProgramButton;

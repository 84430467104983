import React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import TextFieldWithCounter from '../components/TextFieldWithCounter';
import Typography from '@mui/material/Typography';
import { connect } from "react-redux";
import NextButton from '../components/buttons/NextButton';
import store from '../redux/store';
import { setUserinfo, setUserLogout } from "../redux/actions";
import { GET, POST, PUT } from "../helpers/DataProvider";
import { GeneralFunctions } from '../helpers/GeneralFunctions';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { ReactComponent as CloseIcon } from '../assets/icons/trash-2.svg';
import MuiAlert from '@mui/material/Alert';

function SlideTransition(props) {
    return <Slide {...props} direction="up" />;
}

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
class Settings extends React.Component {

    state = {
        name: '',
        family: '',
        mobile: '',
        email: '',
        showSnackbar: false
    }

    setShowSnackbar = () => {
        this.setState({
            showSnackbar: !this.state.showSnackbar
        });
    }

    getUserInformation = async () => {
        let userFullInformation = await GET('profile', null, { 'X-ACCESS-TOKEN': GeneralFunctions.getCurrentToken() });
        this.setState({
            name: userFullInformation[0].name,
            family: userFullInformation[0].family,
            mobile: userFullInformation[0].mobile,
            email: userFullInformation[0].email,
        });
    }

    logOut = () => {
        // get OTP
        let formData = new FormData();
        formData.append('mobile', window.localStorage.getItem('mobile'));
        fetch(`${process.env.REACT_APP_API_URL}auth/logout`, {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, cors, *same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            headers: {
                // 'X-ACCESS-ROLE': 1
            },
            referrer: 'no-referrer', // no-referrer, *client
            body: formData, // body data type must match "Content-Type" header
        })
            .then(logoutResult => {
                window.localStorage.removeItem('token');
                window.localStorage.removeItem('mobile');
                window.localStorage.removeItem('last-location');
                store.dispatch(setUserLogout());
                window.location.href = '#/login';
                // let userData = {
                //     isLogin: false,
                //     token: null
                // }
                // store.dispatch(setUserinfo(userData));
                // return logoutResult;
            })
    }

    handleChange = (pr) => (event) => {
        event.persist();
        this.setState({ [pr]: event.target.value });
    };

    componentDidMount = () => {
        this.getUserInformation();
    }

    updateUserInformation = async () => {
        let res = await PUT("profile",
            {
                'name': this.state.name,
                'family': this.state.family,
                'email': this.state.email,
                'mobile': this.state.mobile,
            },
            null
            ,
            {
                'X-ACCESS-TOKEN': window.localStorage.getItem('token')
            }
        );
        this.setState({
            showSnackbar: true
        });
        let userFullInformation = await GET('profile', null, { 'X-ACCESS-TOKEN': GeneralFunctions.getCurrentToken() })
        let userData = {
            isLogin: true,
            token: GeneralFunctions.getCurrentToken(),
            userFullInformation: userFullInformation[0]
        }
        store.dispatch(setUserinfo(userData));
    }

    render() {

        return (
            <Box
                component="form"
                sx={{
                    '& > :not(style)': {
                        m: 1,
                        // width: '25ch' 
                    },
                }}
                noValidate
                autoComplete="off"
            >

                <Typography>
                    First name
                </Typography>
                <TextFieldWithCounter
                    value={this.state.name}
                    onChange={this.handleChange("name")}
                    showCounter={false}
                />

                <Typography>
                    Last name
                </Typography>
                <TextFieldWithCounter
                    value={this.state.family}
                    onChange={this.handleChange("family")}
                    showCounter={false}
                />

                <Typography>
                    Phone number
                </Typography>
                <TextFieldWithCounter
                    value={this.state.mobile}
                    onChange={this.handleChange("mobile")}
                    showCounter={false}
                />

                <Typography>
                    Last Login
                </Typography>
                <Typography
                    style={{
                        width: 'inherit !important',
                        border: 0,
                        height: 'inherit',
                        padding: 16,
                        position: 'relative',
                        fontSize: 16,
                        transition: 'border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                        fontFamily: '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol"',
                        borderRadius: 10,
                        backgroundColor: '#EFEFF0'
                    }}
                >
                    {this.props.userFullInformation.updatedAt}
                </Typography>

                <Typography>
                    Email address
                </Typography>
                <TextFieldWithCounter
                    value={this.state.email}
                    onChange={this.handleChange("email")}
                    showCounter={false}
                />
                <NextButton
                    onClick={() => this.logOut()}
                // disabled={isDisable()}
                >
                    Logout
                </NextButton>
                <NextButton
                    onClick={() => this.updateUserInformation()}
                // disabled={isDisable()}
                >
                    Save
                </NextButton>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    TransitionComponent={SlideTransition}
                    open={this.state.showSnackbar}
                    onClose={() => this.setShowSnackbar(false)}
                    message="settings saves successfully"
                    autoHideDuration={6000}
                    key={1}
                    action={
                        <React.Fragment>
                            <Button
                                color="primary"
                                size="small"
                                onClick={() => this.setShowSnackbar(false)}
                            >
                                OK
                            </Button>
                        </React.Fragment>
                    }
                >
                    {/* <Alert
                        // onClose={handleClose}
                        severity="success"
                        sx={{ width: '100%' }}
                    >
                        This is a success message!
                        <Button
                                color="primary"
                                size="small"
                                onClick={() => this.setShowSnackbar(false)}
                            >
                                OK
                            </Button>
                    </Alert> */}
                </Snackbar>
            </Box>
        );
    }
}

const mapStateToProps = (state) => {
    const userFullInformation = state.userFullInformation;

    return {
        userFullInformation
    }
};

export default connect(mapStateToProps)(Settings);

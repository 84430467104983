import React from 'react';
import CreatableSelect from 'react-select/creatable';
import { ReactComponent as Closeicon } from '../assets/icons/x.svg';
import { GET, POST } from '../helpers/DataProvider';
import Typography from '@mui/material/Typography';
import { withStyles } from '@material-ui/core/styles';

const useStyles = (theme) => ({
    tag: {
        marginRight: '5px',
        minWidth: '20px',
        height: '34px',
        background: '#E0F1FF',
        borderRadius: '6px',
        display: 'inline-flex',
        justifyContent: 'space-around',
        alignItems: 'center',
        margin: '2px'
    },
    tagLabel: {
        fontSize: '14px',
        fontFamily: 'Quicksand',
        color: '#182A7D',
        margin: '5px'
    },
    tagButton: {
        color: '#182A7D',
        cursor: 'pointer',
        height: '24px',
        margin: '5px'
    }
});

class Tags extends React.Component {

    constructor(props) {
        super(props);
        this.selectInputRef = React.createRef();
    }

    state = {
        selectedTags: [],
        tags: [],
        isDirty: false
    }

    ifTagExists = (tagItem) => {
        return this.state.tags.findIndex(tag => tagItem.label === tag.label) > 0 ? true : false;
    }

    onChangeCreate = (tag) => {
        if (tag) {
            if (this.ifTagExists(tag))
                this.handleChange(tag);
            else
                this.createTag(tag);
            this.selectInputRef.current.removeValue();
        }
    }

    createTag = async (tag) => {
        let res = await POST("tags",
            {
                'title': tag.label,
            },
            null
            ,
            {
                'X-ACCESS-TOKEN': window.localStorage.getItem('token')
            }
        );

        // add to selected tag list
        let tempObj = {};
        tempObj.id = res[0];
        tempObj.value = tag.label;
        tempObj.label = tag.label;
        tempObj.color = '#00B8D9';
        tempObj.isFixed = true;
        let selectedTags = this.state.selectedTags;
        selectedTags.push(tempObj);
        this.setState({
            selectedTags: selectedTags
        });
        let draftProgram = JSON.parse(localStorage.getItem('draftProgram'));
        draftProgram.tags = this.state.selectedTags;
        localStorage.setItem('draftProgram', JSON.stringify(draftProgram));
    }

    getTags = async () => {
        const resultTags = await GET("tags", null, { 'X-ACCESS-TOKEN': window.localStorage.getItem('token') });
        let tempObj = {};
        let bakedTags = [];
        resultTags.map(
            (item, index) => {
                tempObj = {};
                tempObj.id = item._id;
                tempObj.value = item.title;
                tempObj.label = item.title;
                tempObj.color = '#00B8D9';
                tempObj.isFixed = true;
                bakedTags.push(tempObj);
            }
        )
        this.setState({
            tags: bakedTags
        });
    }

    componentDidMount = async () => {
        await this.getTags();
        this.setState({
            selectedTags: this.props.value
        }, () => {
            let newtags = this.state.tags;
            for (let st of this.state.selectedTags) {
                let index = this.state.tags.findIndex(tag => tag.id === st.id);
                newtags.splice(index, 1);
            }
            this.setState({
                tags: newtags
            })
        });
    }

    componentDidUpdate = async (prevProps, prevState, snapshot) => {
        if (this.state.selectedTags != prevProps.value || this.state.selectedTags != prevState.selectedTags) {
            this.setState({
                selectedTags: prevProps.value
            });
        }
        if ((this.state.selectedTags != prevProps.value || this.state.selectedTags != prevState.selectedTags) && prevProps.value.length == 0) {
            this.getTags()
        }
    }

    handleChange = (item) => {
        this.setState({
            isDirty: true
        })
        let ls = this.state.selectedTags;
        let flag = false;
        for (let l of ls) {
            if (l.value === item.value) {
                flag = true
            }
        }

        if (!flag) {
            ls.push(item);
        }

        this.setState({
            selectTag: ls
        }, () => {
            let tempArr = [];
            ls.forEach((item, index) => {
                tempArr.push(item);
            });
            this.props.onChange(tempArr);

            // remove from tags
            let newtags = this.state.tags;
            let index = this.state.tags.findIndex(obj => obj.id === item.id);
            newtags.splice(index, 1);
        });
    }

    removeItem = (item) => {
        let ls = this.state.selectedTags;
        let newLs = [];
        for (let st of ls) {
            if (st.value !== item.value) {
                newLs.push(st)
            }
        }
        let allTags = this.state.tags;
        allTags.push(item);
        this.setState({
            selectedTags: newLs,
            tags: allTags
        }, () => {
            this.props.onChange(this.state.selectedTags);
        });
    }

    render() {
        const { classes } = this.props;
        return (
            <div
                style={{
                    minWidth: '65%'
                }}
            >
                <div>
                    <CreatableSelect
                        ref={this.selectInputRef}
                        isClearable
                        onChange={(item) => this.onChangeCreate(item)}
                        options={this.state.tags}
                        placeholder={'type your tag'}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'left',
                        marginTop: '5px',
                        flexWrap: 'wrap'
                    }}
                >
                    {
                        this.state.selectedTags.map(
                            (selectTag, index) =>
                                <div
                                    key={index}
                                    className={classes.tag}
                                >
                                    <div
                                        className={classes.tagLabel}>
                                        {selectTag.label}
                                    </div>

                                    <div
                                        className={classes.tagButton}
                                        onClick={() => this.removeItem(selectTag)}
                                    >
                                        <Closeicon />
                                    </div>

                                </div>
                        )}
                </div>
                {this.state.isDirty && this.state.selectedTags.length < 3 && <Typography
                    style={{
                        color: '#f44336',
                        marginTop: 2,
                        marginLeft: 4,
                    }}
                >3 tags at least</Typography>}
            </div>
        )
    }

}

export default withStyles(useStyles)(Tags);
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    myBtn: {
        border: '1px solid #182A7D',
        boxSizing: 'border-box',
        borderRadius: '10px',
        [theme.breakpoints.down('sm')]: {
            width: '90%',
        },
        [theme.breakpoints.up('sm')]: {
            width: 122
        },
        height: '44px',
        textTransform: 'none',
        fontFamily: 'Mulish',
        fontWeight: 'bold',
        fontSize: '18px',
        backgroundColor: '#182A7D',
        color: 'white'
    }
}));

const EditProgramButton = (props) => {
    const classes = useStyles();
    const goToEditMode = () => {
        props.onClick();
    }
    return (
        <Button
            className={classes.myBtn}
            variant="outlined"
            color="primary"
            onClick={goToEditMode}
        >
            Edit
        </Button>
    )
}

export default EditProgramButton;
import {
    SET_USER_INFO,
    FETCH_USER_INFORMATION,
    FETCHED_CLIENT_TOKEN,
    SET_USER_LOGOUT,
    SET_USER_LASTLOCATION
} from './constants';

const setUserinfo = (data) => {
    return {
        type: SET_USER_INFO,
        data: data,
    }
}

const setUserLastLocation = (data) => {
    return {
        type: SET_USER_LASTLOCATION,
        data: data
    }
}

const setUserLogout = (data) => {
    return {
        type: SET_USER_LOGOUT,
        data: data,
    }
}

const checkAuthenticationMethod = (data) => (
    {
        type: FETCHED_CLIENT_TOKEN,
        data: data
    }
);

const getUserInformation = (data) => {
    return {
        type: FETCH_USER_INFORMATION,
        data: data,
    }
}

export {
    setUserinfo,
    setUserLogout,
    checkAuthenticationMethod,
    getUserInformation,
    setUserLastLocation
}
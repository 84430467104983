import {
    SET_USER_INFO,
    SET_USER_LOGOUT,
    FETCHED_CLIENT_TOKEN,
    SET_USER_LASTLOCATION
} from "./constants";

const initialState = {
    isLogin: false,
    token: null,
    lastLocation: {},
    userFullInformation: {
        _id: "",
        mobile: "",
        email: "",
        status: 0,
        createdAt: "",
        __v: 0,
        age: 0,
        family: "",
        gender: 1,
        mediaId: null,
        name: "",
        sportId: null,
        updatedAt: "",
        id: "",
    }
};

const reducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_USER_INFO:
            return {
                ...state,
                token: action.data.token,
                isLogin: action.data.isLogin,
                userFullInformation: action.data.userFullInformation
            };

        case SET_USER_LASTLOCATION:
            return {
                ...state,
                lastLocation: action.data
            };

        case SET_USER_LOGOUT:
            return {
                ...state,
                token: null,
                isLogin: false,
                userFullInformation: {
                    // _id: "",
                    mobile: "",
                    email: "",
                    status: 0,
                    createdAt: "",
                    // __v: 0,
                    age: 0,
                    family: "",
                    gender: 1,
                    mediaId: null,
                    name: "",
                    sportId: null,
                    updatedAt: "",
                    id: "",
                }
            };

        case FETCHED_CLIENT_TOKEN:
            return;

        default:
            return state;

    }
};

export default reducer;
import React from 'react';
import { ReactComponent as UploadCloud } from './assets/icons/upload-cloud.svg';
import { ReactComponent as PlusIcon } from './assets/icons/plus.svg';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import SwipeableTemporaryDrawer from './layouts/SwipeableTemporaryDrawer';
import TimeDuration from './components/TimeDuration';
import Counter from './Counter';

class AddTechniquesInMobile extends React.Component {
    constructor(props) {
        super(props);
    }

    state = {
        value: 0,
        addTechniqueRowCount: [{ row: 1 }, { row: 2 }, { row: 3 }],
        anchorEl: null,
        open: false
    }

    setAnchorEl = (event) => {
        this.setState({
            anchorEl: Boolean(event.currentTarget)
        })
    }

    incrementValue = () => {
        this.setState({
            val: this.state.value++
        })
    }

    AddTechnique = () => {
        this.setState({
            addTechniqueRowCount: this.state.addTechniqueRowCount.push({ row: 1 })
        });
    }

    decrementValue = () => {
        this.setState({
            value: this.state.value--
        })
    }

    handleClick = (event) => {

    };

    handleClose = () => {
        this.setAnchorEl(null);
    };

    render() {
        const classes = {
            appBar: {
                display: 'flex',
                justifyContent: 'space-between',
                margin: '15px 0'
            },
            input: {
                width: '34px',
                marginRight: '5px',
                marginLeft: '5px',
                borderStyle: 'none',
                fontSize: '30px',
                '&:focus': {
                    outline: 'none'
                }
            },
            numberOfRepetitions: {
                border: '1px solid #121212',
                borderRadius: '10px',
                width: '162px',
                height: '54px',
                display: 'flex',
                justifyContent: 'center',

            },
            numberOfRepetitionsItem: {
                float: 'left',
                width: '33.33%',
                padding: '10px',
                border: '1px solid black',
                textAlign: 'center',
                borderLeftStyle: 'none',
                borderRightStyle: 'none',
                borderTopStyle: 'none',
                borderBottomStyle: 'none',
                fontFamily: 'Mulish',
                fontStyle: 'normal',
                fontWeight: 'normal',
                fontSize: '24px',
                lineHeight: '30px',
                color: '#121212'
            },
            minusOperator: {
                float: 'left',
                width: '33.33%',
                padding: '10px',
                border: '1px solid black',
                textAlign: 'center',
                '&:hover': {
                    cursor: 'pointer',
                },
                border: '1px solid black',
                borderLeftStyle: 'none',
                borderTopStyle: 'none',
                borderBottomStyle: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            },
            plusOperator: {
                float: 'left',
                width: '33.33%',
                padding: '10px',
                border: '1px solid black',
                textAlign: 'center',
                '&:hover': {
                    cursor: 'pointer',
                },
                border: '1px solid black',
                borderRightStyle: 'none',
                borderTopStyle: 'none',
                borderBottomStyle: 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }
        }

        return (
            <div>

                <div
                    style={{
                        fontFamily: 'Mulish',
                        fontStyle: 'normal',
                        fontWeight: 'bold',
                        fontSize: '18px',
                        lineHeight: '28px',
                        color: '#121212',
                    }}
                >Add techniques for this session: </div>
                <div
                    style={{
                        border: '1px solid #D0D5DA',
                        borderRadius: '10px',
                        lineHeight: 2,
                        padding: '18px 10px'
                    }}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'bold',
                                fontSize: '18px',
                                lineHeight: '28px',
                                color: '#121212',
                            }}
                        >Technique 1</div>
                        <div>
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={() => this.setState({ open: !this.state.open })}
                            >
                                <MoreVertIcon />
                            </IconButton>

                        </div>

                    </div>
                    <div>Upload the techniques video training </div>
                    <div>
                        <Button
                            variant="contained"
                            color="primary"
                            className={classes.button}
                            startIcon={<UploadCloud />}
                        >
                            Upload
                        </Button>
                    </div>
                    <div>Add a name for the technique</div>

                    <div>Time duration for this technique (will shows as coach expectation)</div>
                    <TimeDuration />

                    <div
                        style={{
                            fontFamily: 'Mulish',
                            fontStyle: 'normal',
                            fontWeight: 'normal',
                            fontSize: '14px',
                            lineHeight: '16px',
                            color: '#121212',
                        }}
                    >Number of repetitions</div>
                    <Counter />
                </div>



                <div
                    onClick={() => this.AddTechnique()}
                >
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            border: '1px solid #D0D5DA',
                            borderRadius: '10px',
                            height: 54,
                            marginTop: 10,
                            paddingLeft: 15
                        }}
                    >
                        <div>
                            <PlusIcon
                                style={{
                                    width: 28,
                                    height: 28
                                }}
                            />
                        </div>
                        <div
                            style={{
                                fontFamily: 'Mulish',
                                fontStyle: 'normal',
                                fontWeight: 'normal',
                                fontSize: '20px',
                                lineHeight: '25px',
                                paddingLeft: 10
                            }}
                        >
                            Add more techniques
                        </div>
                    </div>
                    <hr />

                    <div
                        style={{
                            border: '1px solid #182A7D',
                            borderRadius: '12px',
                            height: '76px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <div
                            style={{
                                width: '90%',
                                display: 'flex',
                                alignItems: 'center'
                            }}
                        >
                            <div>
                                <PlusIcon
                                    style={{
                                        color: '#182A7D',
                                        width: 28,
                                        height: 28
                                    }}
                                />
                            </div>
                            <div
                                style={{
                                    fontFamily: 'Mulish',
                                    fontStyle: 'normal',
                                    fontWeight: 'normal',
                                    fontSize: '20px',
                                    lineHeight: '25px',
                                    color: '#182A7D',
                                    paddingLeft: 10
                                }}
                            >
                                Add new session
                            </div>
                        </div>
                    </div>


                </div>
                <SwipeableTemporaryDrawer
                    open={this.state.open}
                    toggleDrawer={() => this.setState({ open: !this.state.open })}
                />
            </div >
        )
    }
}

export default AddTechniquesInMobile;
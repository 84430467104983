import React from 'react';
import Logo from './assets/icons/Subtract@2x.png';
import LogoutButton from './components/buttons/LogoutButton';
import Hidden from '@material-ui/core/Hidden';
import MenuIcon from '@material-ui/icons/Menu';
import IconButton from '@material-ui/core/IconButton';
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import { ReactComponent as HeadphoneIcon } from './assets/icons/user.svg';

const useStyles = (theme) => ({
    appBar: {
        backgroundColor: '#182A7D',
        height: 'inherit',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    appBarInfo: {
        color: 'white'
    },
    userInfo: {
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontWeight: '400',
        fontSize: '20px',
        lineHeight: '25px',
        color: '#FFFFFF'
    },

    container: {
        height: '50%',
        width: '94%',
        display: 'flex',
        justifyContent: 'space-between'
    },

    leftPanel: {
        display: 'flex'
    },

    rightPanel: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        columnGap: '20px'
    },

    helpAndSupport: {
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: '16px',
        textAlign: 'center',
        textDecoration: 'none',
        color: '#FFFFFF',
        '&:hover': {
            textDecoration: 'underline',
        }
    }
});

class AppBar extends React.Component {

    render() {
        const { classes } = this.props;
        return (
            <div
                className={classes.appBar}
            >
                <div
                    className={classes.container}
                >
                    {this.props.isLogin &&
                        <Hidden only={['md', 'lg', 'xl']}>
                            <IconButton
                                style={{
                                    color: 'white'
                                }}
                                onClick={event => this.props.toggleDrawer(event, 'left', true)}
                                edge="start"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Hidden>
                    }
                    <Hidden only={['xs', 'sm']}>
                        <div
                            className={classes.leftPanel}
                        >
                            <div
                                style={{
                                    paddingRight: 9
                                }}>
                                <img
                                    src={Logo}
                                    style={{
                                        height: '45px',
                                        width: '45px'
                                    }}
                                />
                            </div>

                            <div>
                                <div
                                    style={{
                                        fontFamily: 'Mulish',
                                        fontStyle: 'normal',
                                        fontWeight: '800',
                                        fontSize: '16.96px',
                                        lineHeight: '21px',
                                        color: '#FFFFFF',
                                    }}
                                >Smart Coach</div>
                                <div
                                    style={{
                                        fontFamily: 'Mulish',
                                        fontStyle: 'normal',
                                        fontWeight: '300',
                                        fontSize: '10.3644px',
                                        lineHeight: '21px',
                                        letterSpacing: '-0.235556px',
                                        color: '#D2D2D2'
                                    }}
                                >Do the practice, like your coach. </div>
                            </div>
                        </div>
                    </Hidden>
                    <div
                        className={classes.rightPanel}
                    >
                        <Hidden only={['xs', 'sm']}>
                            <div>
                                <a
                                    className={classes.helpAndSupport}
                                    href="/"
                                >Help & support</a>
                            </div>
                            <div>
                                {this.props.isLogin ?
                                    <LogoutButton />
                                    : null}
                            </div>
                        </Hidden>

                        {this.props.isLogin ?
                            <>
                                <div
                                    style={{
                                        fontFamily: 'Mulish',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        fontSize: '20px',
                                        color: '#FFFFFF',
                                    }}
                                >{(this.props.userFullInformation.name || this.props.userFullInformation.family) ? (this.props.userFullInformation.name + ' ' + this.props.userFullInformation.family) : this.props.userFullInformation.mobile}</div>

                                <div
                                    style={{
                                        color: 'white'
                                    }}
                                >
                                    <HeadphoneIcon />
                                </div>
                            </>
                            : null
                        }
                    </div>
                </div>
            </div >
        )
    }
}

const mapStateToProps = (state) => {
    const isLogin = state.isLogin;
    const userFullInformation = state.userFullInformation;
    return {
        isLogin,
        userFullInformation
    }
};
export default connect(mapStateToProps)(withStyles(useStyles)(AppBar));
